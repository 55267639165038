import { useEffect, useRef, useMemo, useCallback, useState } from "react";
import SimplexNoise from "simplex-noise";
import { useControls, button, folder, buttonGroup, Leva } from "leva";
import * as THREE from "three";
import { Canvas, extend, useFrame } from "@react-three/fiber";

import {
  OrbitControls,
  shaderMaterial,
  Lightformer,
  Environment,
} from "@react-three/drei";

const colors = {
  water: ["#003682", "#0045A6", "#0055DC"],
  ice: ["#6ECCE5", "#AFE3F1", "#E2F5FA"],
  fire: ["#ff6633", "#fbad41", "#ffba00"],
};

const { noise2D } = new SimplexNoise();

const randomArray = (array) => array[Math.floor(Math.random() * array.length)];

const randomHex = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

const ColorShiftMaterial = shaderMaterial(
  {
    time: 0,
    resolution: [1024, 1024],
    basecolors: colors.fire,
  },
  // vertex shader
  `varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // fragment shader
  `uniform float time;
    uniform vec2 resolution;
    uniform vec3[3] basecolors;
    varying vec2 vUv;

    #define PI 3.14159265359
    #define WAVES 8.
    
    float wavePosition(vec2 uv, float i) {
        float pos =  sin((uv.x + i * 8.456) * (sin(time * 0.1 + 7.539 + i * 0.139) + 2.) * 0.5) * 0.65
            + sin(uv.x * (sin(time * 0.1 + i * 0.2) + 2.) * 0.3) * 0.3
            - (i - WAVES / 2.) * 2. - uv.y;

        pos = smoothstep(0., 1., pos);

        return pos;
    }

    void main() {
        vec2 uv = vUv;
        vec2 fragCoord = vUv * resolution;
    
        vec2 waveUv = (2. * fragCoord - resolution.xy) / resolution.y * (WAVES - 1.);
    
        float aa = WAVES * 2. / resolution.y;
    
        for (float i = 0.; i < WAVES; i++) {
            float waveTop = wavePosition(waveUv, i);
            float waveBottom = wavePosition(waveUv, i + 1.);
    
            vec3 col = basecolors[int(i) % 3];    
            gl_FragColor.xyz = mix(gl_FragColor.xyz, col, smoothstep(0., aa, waveTop));
        }
    
        gl_FragColor.w = 1.;
    }
  `
);

extend({ ColorShiftMaterial });

const planetTypes = ["sphere", "torus", "tetrahedron", "octahedron"];

const Label = ({ name, setLocked, lockedItems }) => (
  <div
    onClick={() =>
      setLocked(
        lockedItems.includes(name)
          ? lockedItems.filter((a) => a !== name)
          : [...lockedItems, name]
      )
    }
  >
    <svg
      width="10"
      height="10"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
      style={{ marginRight: "4px" }}
    >
      {lockedItems.includes(name) ? (
        <path
          d="M5 4.63601C5 3.76031 5.24219 3.1054 5.64323 2.67357C6.03934 2.24705 6.64582 1.9783 7.5014 1.9783C8.35745 1.9783 8.96306 2.24652 9.35823 2.67208C9.75838 3.10299 10 3.75708 10 4.63325V5.99999H5V4.63601ZM4 5.99999V4.63601C4 3.58148 4.29339 2.65754 4.91049 1.99307C5.53252 1.32329 6.42675 0.978302 7.5014 0.978302C8.57583 0.978302 9.46952 1.32233 10.091 1.99162C10.7076 2.65557 11 3.57896 11 4.63325V5.99999H12C12.5523 5.99999 13 6.44771 13 6.99999V13C13 13.5523 12.5523 14 12 14H3C2.44772 14 2 13.5523 2 13V6.99999C2 6.44771 2.44772 5.99999 3 5.99999H4ZM3 6.99999H12V13H3V6.99999Z"
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      ) : (
        <path
          d="M9 3.63601C9 2.76044 9.24207 2.11211 9.64154 1.68623C10.0366 1.26502 10.6432 1 11.5014 1C12.4485 1 13.0839 1.30552 13.4722 1.80636C13.8031 2.23312 14 2.84313 14 3.63325H15C15 2.68242 14.7626 1.83856 14.2625 1.19361C13.6389 0.38943 12.6743 0 11.5014 0C10.4294 0 9.53523 0.337871 8.91218 1.0021C8.29351 1.66167 8 2.58135 8 3.63601V6H1C0.447715 6 0 6.44772 0 7V13C0 13.5523 0.447715 14 1 14H10C10.5523 14 11 13.5523 11 13V7C11 6.44772 10.5523 6 10 6H9V3.63601ZM1 7H10V13H1V7Z"
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      )}
    </svg>
    {name}
  </div>
);

const generatePlanet = () => {
  const planetType = randomArray(planetTypes);

  const color1 = randomHex();
  const color2 = randomHex();
  const color3 = randomHex();

  const radius = Math.random() + 0.1;
  const tube = radius * Math.random() + 0.01;

  const rotation = [0.4 + Math.random() * 0.2, 0.1 - Math.random() * 0.2];

  const roughness = Math.random();
  const metalness = Math.random();

  const moons = Math.round(Math.random() * 7) - 1;
  const rings = Math.round(Math.random() * 7) - 1;

  return {
    planetType,

    moons,
    rings,

    radius,
    tube,

    rotation,

    color1,
    color2,
    color3,

    roughness,
    metalness,
  };
};

const removeLocked = (config, locked) => {
  const sanitizedConfig = Object.keys(config).reduce(
    (acc, key) => (locked.includes(key) ? acc : { ...acc, [key]: config[key] }),
    {}
  );

  return sanitizedConfig;
};

const Planet = ({ offset, config, handleScreenshot, handlePublish }) => {
  const [locked, setLocked] = useState([]);

  const [
    {
      moons,
      rotation,
      rings,
      roughness,
      metalness,
      radius,
      tube,
      color1,
      color2,
      color3,
      planetType,
      reflectivity,
      transmission,
      thickness,
    },
    set,
  ] = useControls(
    () => ({
      randomize: button(() => {
        const planetConfig = generatePlanet();
        set(removeLocked(planetConfig, locked));
      }),

      moons: {
        value: config.moons,
        step: 1,
        min: -1,
        max: 6,
        label: (
          <Label name="moons" setLocked={setLocked} lockedItems={locked} />
        ),
      },
      rings: {
        value: config.rings,
        step: 1,
        min: -1,
        max: 6,
        label: (
          <Label name="rings" setLocked={setLocked} lockedItems={locked} />
        ),
      },
      rotation: {
        value: config.rotation,
        step: 0.01,
        label: (
          <Label name="rotation" setLocked={setLocked} lockedItems={locked} />
        ),
      },
      planetType: {
        value: config.planetType,
        options: planetTypes,
        label: (
          <Label name="planetType" setLocked={setLocked} lockedItems={locked} />
        ),
      },
      roughness: {
        value: config.roughness,
        step: 0.01,
        min: 0,
        max: 1,
        label: (
          <Label name="roughness" setLocked={setLocked} lockedItems={locked} />
        ),
      },
      metalness: {
        value: config.metalness,
        step: 0.01,
        min: 0,
        max: 1,
        label: (
          <Label name="metalness" setLocked={setLocked} lockedItems={locked} />
        ),
      },
      radius: {
        value: config.radius,
        step: 0.01,
        min: 0,
        max: 1,
        label: (
          <Label name="radius" setLocked={setLocked} lockedItems={locked} />
        ),
      },
      tube: {
        value: config.tube,
        step: 0.01,
        min: 0,
        max: 1,
        label: <Label name="tube" setLocked={setLocked} lockedItems={locked} />,
      },

      color: folder({
        color1: {
          value: config.color1,
          label: (
            <Label name="color1" setLocked={setLocked} lockedItems={locked} />
          ),
        },
        color2: {
          value: config.color2,
          label: (
            <Label name="color2" setLocked={setLocked} lockedItems={locked} />
          ),
        },
        color3: {
          value: config.color3,
          label: (
            <Label name="color3" setLocked={setLocked} lockedItems={locked} />
          ),
        },

        presets: buttonGroup({
          fire: () =>
            set({
              color1: colors.fire[0],
              color2: colors.fire[1],
              color3: colors.fire[2],
            }),
          ice: () =>
            set({
              color1: colors.ice[0],
              color2: colors.ice[1],
              color3: colors.ice[2],
            }),
          water: () =>
            set({
              color1: colors.water[0],
              color2: colors.water[1],
              color3: colors.water[2],
            }),
        }),
      }),

      advanced: folder(
        {
          reflectivity: {
            value: config.reflectivity,
            step: 0.01,
            min: 0,
            max: 1,
          },
          transmission: {
            value: config.transmission,
            step: 0.01,
            min: 0,
            max: 1,
          },
          thickness: {
            value: config.thickness,
            step: 0.01,
            min: 0,
            max: 1,
          },
        },
        { collapsed: true }
      ),

      screenshot: button(handleScreenshot),
      publish: button((get) =>
        handlePublish({
          moons: get("moons"),
          rotation: get("rotation"),
          rings: get("rings"),
          roughness: get("roughness"),
          metalness: get("metalness"),
          radius: get("radius"),
          tube: get("tube"),
          color1: get("color.color1"),
          color2: get("color.color2"),
          color3: get("color.color3"),
          planetType: get("planetType"),
          reflectivity: get("advanced.reflectivity"),
          transmission: get("advanced.transmission"),
          thickness: get("advanced.thickness"),
        })
      ),
    }),
    {},
    [locked]
  );

  const colorUniform = useMemo(
    () => ({
      value: [color1, color2, color3].map((color) => new THREE.Color(color)),
    }),
    []
  );

  const timeUniform = useMemo(
    () => ({
      value: 0,
    }),
    []
  );

  useEffect(() => {
    colorUniform.value = [color1, color2, color3].map(
      (color) => new THREE.Color(color)
    );
  }, [color1, color2, color3]);

  const ref = useRef();
  const mouseLightRef = useRef();
  useFrame(({ mouse }, delta) => {
    timeUniform.value += delta;

    if (mouseLightRef.current) {
      const x = (mouse.x * window.outerWidth) / 2;
      const y = (mouse.y * window.outerHeight) / 2;
      mouseLightRef.current.position.set(x, y, 0);
      mouseLightRef.current.rotation.set(-y, x, 0);
    }
  });

  const moonPoints = useMemo(() => {
    let pts = [];
    if (moons > -1) {
      for (var i = 0; i < Math.pow(2, moons); i++) {
        const radius = Math.random() * 1.0 + 1.0;
        var phi = Math.acos(2 * Math.random() - 1);
        var theta = 4 * Math.PI * Math.random();
        pts.push({
          position: new THREE.Vector3().setFromSphericalCoords(
            radius,
            phi,
            theta
          ),
          radius: Math.random() * 0.1 + 0.05,
        });
      }
    }
    return pts;
  }, [moons]);

  const ringPoints = useMemo(() => {
    let pts = [];
    if (rings > -1) {
      for (var i = 0; i < Math.pow(2, rings); i++) {
        pts.push({
          radius: Math.random() * 1 + 1.3,
          tube: 0.03 * Math.random() + 0.005,
        });
      }
    }
    return pts;
  }, [rings]);

  const onBeforeCompile = useCallback((shader) => {
    const funcFrag = `
#define WAVES 9.

uniform float time;
//uniform vec2 resolution;
uniform vec3[3] basecolors;
varying vec2 vUv;

float wavePosition(vec2 uv, float i) {
  return sin((uv.x * 1.2 + i * PI * 2. * time/10.) + PI-0.02 * uv.x)
        - (i - WAVES / 2.) * 2. - uv.y;
}
    `;

    const fragColor = `
#ifndef USE_TRANSMISSION

  vec2 resolution = vec2(1024., 1024.);
  vec2 fragCoord = vUv * resolution;
  vec2 waveUv = (2. * fragCoord - resolution.xy) / resolution.y * 1. * (WAVES - 1.);
  
  float aa = WAVES * 2. / resolution.y;
  
  for (float i = 0.; i < WAVES; i++) {
  float waveTop = wavePosition(waveUv, i);
  float waveBottom = wavePosition(waveUv, i + 1.);
  
  vec3 col = basecolors[int(i) % 3]; 
  diffuseColor.xyz = mix(diffuseColor.xyz, col, smoothstep(0., aa, waveTop));
  }

#endif

    `;

    shader.uniforms.basecolors = colorUniform;
    shader.uniforms.time = timeUniform;

    shader.fragmentShader = shader.fragmentShader.replace(
      "void main() {",
      `${funcFrag}
      \n
      void main() {`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <color_fragment>",
      fragColor
    );

    shader.vertexShader = `#define USE_UV\n${shader.vertexShader}`;
  });

  return (
    <group position={[(offset[0] - 0.5) * 20, 0, (offset[1] - 0.5) * 20]}>
      {/* <pointLight ref={mouseLightRef} position={[5, 5, 5]} intensity={20} /> */}

      {/* TODO — rotation */}
      <mesh>
        {planetType === "sphere" ? (
          <sphereGeometry args={[radius, 24, 24]} />
        ) : planetType === "torus" ? (
          <torusGeometry args={[radius, tube, 16, 64]} />
        ) : planetType === "tetrahedron" ? (
          <tetrahedronGeometry args={[radius, 0]} />
        ) : planetType === "octahedron" ? (
          <octahedronGeometry args={[radius, 0]} />
        ) : null}

        <meshPhysicalMaterial
          ref={ref}
          onBeforeCompile={onBeforeCompile}
          roughness={roughness}
          metalness={metalness}
          transmission={transmission}
          reflectivity={reflectivity}
          thickness={thickness}
        />
      </mesh>

      {ringPoints.map(({ radius, tube }, i) => (
        <mesh
          rotation-x={rotation[0] * Math.PI}
          rotation-y={rotation[1] * Math.PI}
        >
          <torusGeometry args={[radius, tube, 8, 64]} />
          <meshPhysicalMaterial
            color={[color1, color2, color3][i % 3]}
            roughness={roughness}
            metalness={metalness}
          />
        </mesh>
      ))}

      {moonPoints.map(({ position, radius }, i) => (
        <mesh position={position}>
          <sphereGeometry args={[radius, 12, 12]} />
          <meshPhysicalMaterial
            color={[color1, color2, color3][i % 3]}
            roughness={roughness}
            metalness={metalness}
          />
        </mesh>
      ))}
    </group>
  );
};

const saveFile = function (strData, filename) {
  var link = document.createElement("a");
  if (typeof link.download === "string") {
    document.body.appendChild(link); //Firefox requires the link to be in the body
    link.download = filename;
    link.href = strData;
    link.click();
    document.body.removeChild(link); //remove the link when done
  }
};

function App() {
  const [planet, setPlanet] = useState({
    moons: 2,
    rotation: [0.6, -0.1],
    rings: 2,
    roughness: 1,
    metalness: 0,
    radius: 0.5,
    tube: 0.3,
    color1: colors["fire"][0],
    color2: colors["fire"][1],
    color3: colors["fire"][2],
    planetType: "sphere",
    reflectivity: 0.5,
    transmission: 0.0,
    thickness: 0.0,
  });

  const inputRef = useRef();
  const canvasRef = useRef();

  const [userId, setUserId] = useState(localStorage.getItem("user_id"));
  const [fetching, setFetching] = useState(false);

  const handleScreenshot = () => {
    const imageData = canvasRef.current.toDataURL("image/png");
    const filename = `planet-${Math.floor(Math.random() * 16777215).toString(
      16
    )}.png`;
    saveFile(imageData, filename);
  };

  const handlePublish = (planetConfig) => {
    if (!userId) return false;

    fetch(`https://space.hturan.com/api/planets/${userId}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(planetConfig),
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let id = params.get("id");

    if (id) {
      setFetching(true);
      fetch(`https://space.hturan.com/api/planets/${id}`)
        .then((res) => res.json())
        .then((json) => {
          setPlanet(JSON.parse(json));
          setFetching(false);
        });
    }

    console.log(id);
  }, []);

  // useEffect(() => {
  //   fetch("https://space.hturan.com/api/planets")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       setPlanets(json);
  //     });
  // }, []);

  return (
    <main
      style={{
        // background: "rgb(0, 15, 38)",
        backgroundImage: `radial-gradient(rgb(20, 35, 58), rgb(0, 15, 38))`,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {userId ? (
        <>
          <Leva hideCopyButton={true} titleBar={{ filter: false }} />
          <Canvas
            flat
            gl={{ physicallyCorrectLights: true, preserveDrawingBuffer: true }}
            ref={canvasRef}
          >
            {/* <Environment preset="lobby" /> */}
            <Environment
              path={`cube/`}
              files={[
                `px.png`,
                `nx.png`,
                `py.png`,
                `ny.png`,
                `pz.png`,
                `nz.png`,
              ]}
            />
            <OrbitControls enablePan={false} enableZoom={false} />

            <ambientLight intensity={0.7} />
            <pointLight position={[5, 5, 5]} power={300} />

            {!fetching && (
              <Planet
                config={planet}
                offset={[0.5, 0.5]}
                handleScreenshot={handleScreenshot}
                handlePublish={handlePublish}
              />
            )}
          </Canvas>
        </>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const name = inputRef.current.value;

            if (name.trim() === "") return;

            localStorage.setItem("user_id", name);
            setUserId(name);
          }}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p style={{ color: "white", fontWeight: "bold" }}>
            Welcome to space! 👋
          </p>
          <p style={{ color: "white", margin: 0 }}>
            Enter your name below to get started
          </p>
          <input
            style={{
              borderRadius: 12,
              padding: 16,
              margin: 0,
              marginBottom: 16,
              marginTop: 16,
              fontSize: 16,
              backgroundColor: "rgb(6, 46, 71)",
              border: "2px solid rgb(55, 86, 122)",
              color: "white",
            }}
            autoComplete={false}
            autoCapitalize={false}
            ref={inputRef}
            defaultValue=""
            type="text"
          />
          <button
            style={{
              fontSize: 16,
              border: "none",
              padding: 16,
              borderRadius: 12,
              backgroundColor: "rgb(0, 136,255)",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Enter
          </button>
        </form>
      )}
    </main>
  );
}

export default App;
